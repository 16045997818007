@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    overflow: auto;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    @apply font-asap;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

.wizard-width {
    @apply w-full lg:w-[1000px];
}

.results-extraction-width {
    @apply w-full lg:w-[1400px];
}

.project-view-width {
    @apply w-full xl:w-[1200px] 2xl:w-[1500px];
}

.project-card-size {
    @apply w-full md:max-w-[430px] md:min-w-[430px] md:h-[370px];
}

/* In your global CSS file, or as inline CSS in the component */
@keyframes pulse {
    0%,
    100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.5);
        opacity: 0.7;
    }
}

.pulsing-dot {
    animation: pulse 0.8s infinite ease-in-out;
}

/*document view*/
.markdown-text {
    @apply text-veryDarkGray dark:text-veryLightGray bg-white dark:bg-bgBlack text-sm;
}

.markdown-text h1 {
    @apply text-xl font-semibold;
}

.markdown-text h2 {
    @apply text-base font-bold;
}

.markdown-text h3 {
    @apply text-sm font-bold;
}

.markdown-text code {
    @apply bg-lightGray dark:bg-darkGray;
}

.pico-table {
    @apply border-collapse w-full;
}

.pico-table th,
.pico-table td {
    @apply p-2 border border-gray text-left;
}

.pico-table .head,
.pico-table .title {
    @apply bg-lightGray dark:bg-darkGray font-bold text-center;
}

.tooltip-trigger {
    @apply text-yellow relative cursor-pointer inline-block;
    /* The text you hover over stays blue */
}

.tooltip-trigger .tooltiptext {
    @apply hidden absolute z-[9999] bg-white dark:bg-bgBlack rounded-md shadow-customShadow w-[520px] px-2 py-1;
}

.tooltip-trigger .tooltiptext .tooltip-desc {
    @apply italic text-veryDarkGray dark:text-veryLightGray;
}

.tooltip-trigger .tooltiptext .tooltip-title {
    @apply font-bold text-veryDarkGray dark:text-veryLightGray;
}

.tooltip-trigger .tooltiptext .tooltip-authors {
    @apply text-veryDarkGray dark:text-veryLightGray;
}

.tooltip-trigger:hover .tooltiptext {
    @apply block;
    opacity: 1;
}

/* Custom scrollbar styles for Webkit browsers (Chrome, Safari, etc.) */
::-webkit-scrollbar {
    width: 8px; /* Width of the scrollbar */
}

::-webkit-scrollbar-thumb {
    background-color: rgba(40, 116, 166, 0.64); /* Light mode color */
    border-radius: 8px;
}

::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent track */
    border-radius: 8px;
}

/* For dark mode */
.dark ::-webkit-scrollbar-thumb {
    background-color: rgba(255, 172, 39, 0.67); /* Dark mode color */
    border-radius: 8px;
}

.dark ::-webkit-scrollbar-track {
    background-color: transparent; /* Transparent track in dark mode */
}

/* Hide the scrollbar in light mode */
body::-webkit-scrollbar {
    display: none !important;
}

/* Light theme style for scrollbarfiller on MUI data grid */
html:not(.dark) .MuiDataGrid-scrollbarFiller--header {
    background-color: white !important;
}

/* Dark theme style for scrollbarfiller on MUI data grid */
@media (prefers-color-scheme: dark) {
    .MuiDataGrid-scrollbarFiller--header {
        background-color: #2c3e50 !important;
    }
}
